import { useTranslation } from 'react-i18next';

import Carousel2, { CarouselData2 } from 'src/components/Common/Carousel2';

const carouselsData: CarouselData2[] = [
  {
    id: 1,
    title: 'The Gioi Skinfood Uplifts Transaction with BNPL Solutions',
    stream: 'Thegioiskinfood x Fundiin',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/case-studies/_casestudy1.jpeg',
    source: '/case-study/skinfood',
  },
  {
    id: 2,
    title: 'Fundiin Split Payment Price-tag',
    stream: 'Dottie x Fundiin',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/case-studies/_casestudy2.jpeg',
    source: '/case-study/dottie',
  },
  {
    id: 3,
    title: 'BNPL Meets Cinema',
    stream: 'Lotte x Fundiin',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/case-studies/_casestudy3.jpeg',
    source: '/case-study/lotte',
  },
  {
    id: 4,
    title: 'BNPL - MKB Order Value Booster',
    stream: 'KidsPlaza x Fundiin',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/case-studies/_casestudy4.jpeg',
    source: '/case-study/kids-plaza',
  },
];

const CaseStudy = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="case-stadies">
        <h3 className="case-stadies__title">
          {t('partners.titles.caseStudy')}
        </h3>
        <Carousel2 data={carouselsData} />
      </div>
    </div>
  );
};

export default CaseStudy;
