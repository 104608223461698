import { useTranslation } from 'react-i18next';

const Vision = () => {
  const { t } = useTranslation();

  return (
    <div className="vision">
      <div className="vision__content">
        <div className="vision__img">
          <img
            className="cover-img"
            src="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/_vision.png"
            alt="vision"
          />
        </div>
        <div className="vision__text">
          <div>
            <h3 className="vision__title">{t('about.titles.visionMission')}</h3>
            <p>{t('about.descriptions.visionMission1st')}</p>
            <p>{t('about.descriptions.lessWorryLiveFully2nd')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
