import { useTranslation } from 'react-i18next';

import DownloadApp from 'src/components/DownloadApp';
import { Hero } from 'src/components/Layouts';
import { useDocumentTitle } from 'src/hooks';
import CoreValue from 'src/pages/about/components/CoreValue';
import LifeBester from 'src/pages/about/components/LifeBester';
import Review from 'src/pages/about/components/Review';

import Investor from './components/Investor';
import Vision from './components/Vision';

const About = () => {
  useDocumentTitle('Fundiin - About');

  const { t } = useTranslation();

  return (
    <>
      <Hero
        title={t('about.titles.heroText')}
        heroUrl="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/hero/_hero-free.jpeg"
        heroMobileUrl="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/hero/_hero-free-mobile.jpeg"
        heroTabletUrl="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/hero/_hero-free-tablet.jpeg"
      />
      <LifeBester />
      <Vision />
      <CoreValue />
      <Review />
      <Investor />
      <DownloadApp />
    </>
  );
};

export default About;
