import { useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Select } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from 'src/assets/svg';
import { MERCHANT_TYPES } from 'src/common/constants/choices';
import { regexPhoneNumber } from 'src/common/validations/regex';
import PrimaryButton from 'src/components/Common/PrimaryButton';

const { Option } = Select;

const sheetUrl = process.env.REACT_APP_SHEET_URL;
const sheetName = process.env.REACT_APP_SHEET_NAME;

const RegisterForm = () => {
  const [responseMess, setResponseMess] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const handleSubmit = (values: any) => {
    setLoading(true);
    (window as any).SheetDB.write(sheetUrl, {
      sheet: sheetName,
      data: values,
    }).then(({ created }: any) => {
      if (created) {
        setLoading(false);
        setResponseMess(
          'Whoosh! Fundiin sẽ liên lạc ngay khi nhận được thông tin!'
        );
      }
    });
  };

  return (
    <div className="register" id="register">
      <div className="container">
        <h3 className="register__title">
          {t('partners.titles.registerAsFundiinPartner')}
        </h3>
        <p className="register__subtitle">
          {t('partners.descriptions.registerAsFundiinPartner')}
        </p>
      </div>
      <div className="container">
        <div
          className={cx({
            register__form: true,
            'register__form--success': responseMess,
          })}
        >
          {responseMess ? (
            <div className="register__success">
              <Icon.RegisterSuccess />
              <p className="register__success-text">{responseMess}</p>
            </div>
          ) : (
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              form={form}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="Company Name"
                label={t('partners.labels.companyName')}
                rules={[{ required: true, message: t('errors.fieldRequire') }]}
              >
                <Input
                  size="large"
                  placeholder={t('partners.placeholder.companyName')}
                />
              </Form.Item>
              <Form.Item
                name="Goods"
                label={t('partners.labels.businessSector')}
                rules={[{ required: true, message: t('errors.fieldRequire') }]}
              >
                <Select
                  size="large"
                  placeholder={t('partners.placeholder.businessSector')}
                  allowClear
                >
                  {MERCHANT_TYPES.map(category => (
                    <Option key={category.value} value={category.value}>
                      {category.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="Phone"
                label={t('partners.labels.contactPhoneNumber')}
                rules={[
                  { required: true, message: t('errors.fieldRequire') },
                  {
                    pattern: regexPhoneNumber,
                    message: t('errors.invalidPhone'),
                  },
                ]}
              >
                <Input
                  type="tel"
                  size="large"
                  placeholder={t('partners.placeholder.contactPhoneNumber')}
                />
              </Form.Item>
              <Form.Item
                name="Contact Name"
                label={t('partners.labels.contactName')}
                rules={[{ required: true, message: t('errors.fieldRequire') }]}
              >
                <Input
                  size="large"
                  placeholder={t('partners.placeholder.contactName')}
                />
              </Form.Item>
              <Form.Item
                name="Email"
                label={t('partners.labels.email')}
                rules={[
                  { required: true, message: t('errors.fieldRequire') },
                  { type: 'email', message: t('errors.invalidEmail') },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t('partners.placeholder.email')}
                />
              </Form.Item>
              <Form.Item
                name="Position"
                label={t('partners.labels.businessSocial')}
                rules={[
                  { required: true, message: t('errors.fieldRequire') },
                  { type: 'url', message: t('errors.invalidUrl') },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t('partners.placeholder.businessSocial')}
                />
              </Form.Item>

              <PrimaryButton type="submit" className="register-btn">
                {loading ? <LoadingOutlined /> : t('actions.send')}
              </PrimaryButton>
            </Form>
          )}
        </div>
      </div>
      <div className="register__bg">
        <img
          src="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/register/_bg.webp"
          alt="register bg"
        />
      </div>
    </div>
  );
};

export default RegisterForm;
