import React from 'react';

import ReactDOM from 'react-dom/client';

import App from 'src/app/App';
import AppProvider from 'src/app/AppProvider';
import i18next from 'src/i18n';

import 'src/styles/base/_reset.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18next.changeLanguage('vi');

root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);
