import { Carousel, CarouselProps } from 'antd';
import classNames from 'classnames';

export interface CarouselBaseProps extends CarouselProps {}

const CarouselBase = ({ className, ...rest }: CarouselBaseProps) => {
  return (
    <Carousel
      swipe
      className={classNames('carousel-base', className)}
      draggable
      infinite={false}
      dots={false}
      speed={400}
      autoplaySpeed={4000}
      {...rest}
    />
  );
};

export default CarouselBase;
