import { Tag } from 'antd';
import { CheckableTagProps } from 'antd/es/tag';
import classNames from 'classnames';

export interface TagCheckableProps extends CheckableTagProps {}

const TagCheckable = ({ className, ...rest }: TagCheckableProps) => {
  return (
    <Tag.CheckableTag
      className={classNames(className, 'tag-checkable')}
      {...rest}
    />
  );
};

export default TagCheckable;
