import { GuideStepsData } from 'src/types';

export const PAY_INSTALLMENT_GUIDE: GuideStepsData[] = [
  {
    id: '1',
    img: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/direction/_periodic1.webp',
    title: 'Tải ứng dụng Fundiin',
    description: (
      <p>
        Tải ứng dụng với từ khóa “Fundiin” trên App Store hoặc Google Play Store
        và thực hiện đăng nhập vào ứng dụng.
      </p>
    ),
  },
  {
    id: '2',
    img: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/direction/_periodic2.webp',
    title: 'Chọn hoá đơn thanh toán',
    description: (
      <p>
        Vào mục “Hóa đơn”, chọn đơn hàng cần thanh toán và bấm “Thanh toán”.
      </p>
    ),
  },
  {
    id: '3',
    img: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/direction/_periodic3.webp',
    title: 'Chọn phương thức thanh toán',
    description: (
      <p>
        Chọn phương thức thanh toán phù hợp và làm theo hướng dẫn của phương
        thức đã chọn.
      </p>
    ),
  },
];
