import { lazy, Suspense } from 'react';

import { Route, Routes } from 'react-router-dom';

import { MENU } from 'src/common/constants/const';
import { Layout } from 'src/components/Layouts';
import LoadingFullscreen from 'src/components/Loadings/LoadingFullscreen';
import About from 'src/pages/about';
import Brands from 'src/pages/brands';
import Home from 'src/pages/home';
import Partners from 'src/pages/partners';

// const Home = lazy(() => import('src/pages/home'));
// const Brands = lazy(() => import('src/pages/brands'));
// const Partners = lazy(() => import('src/pages/partners'));
// const About = lazy(() => import('src/pages/about'));
const Privacy = lazy(() => import('src/pages/privacy'));
const ContractPrinciples = lazy(() => import('src/pages/contract-principles'));
const PurchaseContract = lazy(() => import('src/pages/purchase-contract'));
const DeferredPurchaseOrder = lazy(
  () => import('src/pages/DeferredPurchaseOrder')
);
const CaseStudy = lazy(() => import('src/pages/case-study'));
const DeleteAccount = lazy(() => import('src/pages/DeleteAccount'));
const TermOfUserUQR = lazy(() => import('src/pages/terms-of-use-uqr'));
const MerchantPolicy = lazy(() => import('src/pages/merchant-policy'));

const AppRouter = () => {
  return (
    <Suspense fallback={<LoadingFullscreen />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={MENU[0].path} element={<Brands />} />
          <Route path={MENU[1].path} element={<Partners />} />
          <Route path={MENU[3].path} element={<About />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
        </Route>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contract-principles" element={<ContractPrinciples />} />
        <Route path="/purchase-contract" element={<PurchaseContract />} />
        <Route
          path="/deferred-purchase-order"
          element={<DeferredPurchaseOrder />}
        />
        <Route path="/case-study/:id" element={<CaseStudy />} />
        <Route path="/terms-of-use-uqr" element={<TermOfUserUQR />} />
        <Route path="/merchant-policy" element={<MerchantPolicy />} />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
