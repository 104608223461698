import { ReactElement, ReactNode, useState } from 'react';

import cx from 'classnames';

import FundiinButton from 'src/components/Common/FundiinButton';

export type PropsTab1ContentSection = {
  id: number;
  content: ReactElement;
  title: ReactElement | ReactNode;
};

type PropsTab1Section = {
  title?: string;
  mini?: boolean;
  medium?: boolean;
  defaultActiveTab?: number;
  tabs:
    | PropsTab1ContentSection[]
    | ((active: boolean) => PropsTab1ContentSection[]);
  onTabChange?: (id: number) => void;
} & React.HTMLAttributes<HTMLDivElement>;

const Tab1 = ({
  tabs,
  className,
  title = '',
  mini = false,
  medium = false,
  defaultActiveTab = 0,
  onTabChange = () => {},
}: PropsTab1Section) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const handleActiveTab = (id: number) => {
    onTabChange(id);
    setActiveTab(id);
  };

  return (
    <div className={cx(['tab1', className])}>
      {title ? <h2 className="tab1__title color-grey-1000">{title}</h2> : null}
      <div
        className={cx({
          mini,
          tab1__contronler: true,
        })}
      >
        {(typeof tabs === 'function'
          ? tabs(activeTab === defaultActiveTab)
          : tabs
        ).map(ta => (
          <FundiinButton
            key={ta.id}
            className={cx({
              mini,
              medium,
              active: activeTab === ta.id,
            })}
            onClick={() => handleActiveTab(ta.id)}
          >
            {ta.title}
          </FundiinButton>
        ))}
      </div>
      <div className="tab1__content">
        {(typeof tabs === 'function'
          ? tabs(activeTab === defaultActiveTab)
          : tabs
        ).map(ta =>
          activeTab === ta.id ? (
            <div key={ta.id} className="tab1__item">
              {ta.content}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default Tab1;
