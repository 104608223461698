import { useTranslation } from 'react-i18next';

import EightShellSection, {
  SubPropsEightShellSection,
} from 'src/components/Common/EightShellSection';

const Content: SubPropsEightShellSection[] = [
  {
    id: 1,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/integration/_haravan.png',
  },
  {
    id: 2,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/integration/_shopify.png',
  },
  {
    id: 3,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/integration/_sapo.png',
  },
  {
    id: 4,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/integration/_pancake.png',
  },
  {
    id: 5,
    imgUrl: '',
  },
  {
    id: 6,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/integration/_commerce.png',
  },
  {
    id: 7,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/integration/_mageto.png',
  },
  {
    id: 8,
    imgUrl: '',
  },
];

const Integration = () => {
  const { t } = useTranslation();

  return (
    <EightShellSection
      shells={Content}
      className="integration"
      title={t('partners.titles.diversePaymentIntegrations')}
      subtitle={t('partners.descriptions.diversePaymentIntegrations')}
    />
  );
};

export default Integration;
