import React, { useEffect, useMemo, useState } from 'react';

import { Modal, Spin } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { cloneDeep, isSmallerPc } from 'src/common/utils/helper';
import FundiinButton from 'src/components/Common/FundiinButton';
import { useWindowSize } from 'src/hooks';
import useBrands from 'src/pages/brands/hooks/useBrands';
import { BrandQuery } from 'src/services/service-types';

import useCategory from '../../hooks/useCategory/useCategory';
import MenuMobile from './CategoryMobile';
import { EStatue } from './const';

const defaultActiveTabs: boolean[] = [];

const Category = ({ defaultActive = 0 }: { defaultActive?: number }) => {
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const { data: brandsData, isLoading } = useBrands();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTabs, setActiveTabs] = useState<boolean[]>([]);
  const [activeTabs2, setActiveTabs2] = useState<string[]>([]);

  const { brands, buys } = useCategory();

  const result = useMemo(() => {
    let activeBrands = brands;

    if (activeTabs.includes(true)) {
      activeBrands = brands.filter((_, i) => activeTabs[i]);
    }

    const activeBrandsName = activeBrands.map(b => b.altName);

    const filterMerchants = (brand: BrandQuery) => {
      return {
        ...brand,
        merchants: activeTabs2.some(a => !!a)
          ? brand.merchants.filter(merchant =>
              activeTabs2.includes(merchant.runningForm)
            )
          : brand.merchants,
      };
    };

    return brandsData
      .filter(brand => activeBrandsName.includes(brand.altName))
      .map(brand => filterMerchants(brand));
  }, [brandsData, activeTabs, activeTabs2]);

  const count = useMemo(() => {
    let c = 0;
    result?.forEach(r => {
      c += r.merchants.length;
    });
    return c;
  }, [result]);

  const selectedCategory = useMemo(
    () => brands.find((_, i) => !!activeTabs[i])?.text || '',
    [activeTabs]
  );

  const handleActiveTab = (id: number) => {
    let atTabs = cloneDeep(activeTabs);

    atTabs[id] = !atTabs[id];

    // this line for single choise
    atTabs = atTabs.map((val: boolean, i: number) => (id === i ? val : false));

    setActiveTabs(atTabs);
  };

  const handleActiveTab2 = (id: number, key: EStatue) => {
    const atTabs = cloneDeep(activeTabs2);
    atTabs[id] = atTabs[id] ? EStatue.UNCHECK : key;
    setActiveTabs2(atTabs);
  };

  const handleOpenModel = () => {
    setIsModalOpen(true);
  };

  const handleOk = (vals: boolean[]) => {
    setActiveTabs(vals);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    defaultActiveTabs[defaultActive] = true;
    setActiveTabs(defaultActiveTabs);
  }, [defaultActive]);

  return (
    <div className="container">
      <div className="category">
        <div className="category__sidebar">
          <h3 className="category__title">{t('brands.titles.category')}</h3>
          <div className="category__menu">
            {brands.map(b => (
              <FundiinButton
                key={b.id}
                className={cx({
                  active: activeTabs[b.id],
                  medium: isSmallerPc(width),
                })}
                onClick={() => handleActiveTab(b.id)}
              >
                {activeTabs[b.id] ? b.aciveIcon : b.icon} {b.text}
              </FundiinButton>
            ))}
          </div>
          <h3 className="category__title">{t('brands.titles.purchase')}</h3>
          <div className="category__menu">
            {buys.map(b => (
              <FundiinButton
                key={b.id}
                className={cx({
                  medium: isSmallerPc(width),
                  active: activeTabs2[b.id] === b.value,
                })}
                onClick={() => handleActiveTab2(b.id, b.value)}
              >
                {b.text}
              </FundiinButton>
            ))}
          </div>
        </div>
        <div className="category__result">
          <p className="category__count">
            {count} {t('brands.titles.result')}
          </p>
          <div className="category__sidebar--mobile">
            {buys.map(b => (
              <FundiinButton
                key={b.id}
                className={cx({
                  mini: true,
                  active: activeTabs2[b.id] === b.value,
                })}
                onClick={() => handleActiveTab2(b.id, b.value)}
              >
                {b.text}
              </FundiinButton>
            ))}
            <FundiinButton
              className={cx({
                mini: true,
                'filter-btn': true,
                active: selectedCategory,
              })}
              onClick={handleOpenModel}
            >
              {t('brands.titles.category')}
              {selectedCategory ? (
                <>
                  (
                  <span
                    className="line-clamp"
                    style={{
                      maxWidth: '5.125rem',
                      textAlign: 'left',
                    }}
                  >
                    {selectedCategory}
                  </span>
                  )
                </>
              ) : (
                ''
              )}
            </FundiinButton>
          </div>
          <div className="category__list">
            {isLoading ? (
              <Spin spinning />
            ) : (
              result?.map(d => (
                <React.Fragment key={d.id}>
                  {d.merchants.map(m => (
                    <div
                      key={`${d.id}-${m.keyTracking}`}
                      className="category__item"
                    >
                      <Link to={m.website}>
                        <img className="cover-img" src={m.image} alt="item" />
                      </Link>
                      {['ONLINE', 'OFFLINE'].includes(m.runningForm) ? (
                        <div
                          className={cx({
                            tag: true,
                            'tag--online': m.runningForm === EStatue.ONLINE,
                            'tag--offline': m.runningForm !== EStatue.ONLINE,
                          })}
                        >
                          <span>{m.runningForm}</span>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </React.Fragment>
              ))
            )}
          </div>
        </div>
      </div>

      <Modal
        width={'100%'}
        open={isModalOpen}
        title={t('brands.titles.category')}
        footer={<div></div>}
        onCancel={handleCancel}
      >
        <MenuMobile activeTabs={activeTabs} onChange={handleOk} />
      </Modal>
    </div>
  );
};

export default Category;
