import { SyntheticEvent, useEffect, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { Icon } from 'src/assets/svg';
import { MENU } from 'src/common/constants/const';
import { getScrollbarWidth, isLargerTablet } from 'src/common/utils/helper';
import PrimaryButton from 'src/components/Common/PrimaryButton';
import { useWindowSize } from 'src/hooks';

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t }: { t: any } = useTranslation();
  const [width] = useWindowSize();
  const [collapseState, setCollapseState] = useState(false);

  const handleCollapseMenu = () => {
    setCollapseState(old => !old);
  };

  useEffect(() => {
    if (isLargerTablet(width)) {
      setCollapseState(false);
    }
  }, [width]);

  return (
    <div
      style={{
        maxWidth: `calc(100vw - ${getScrollbarWidth()}px)`,
      }}
      className={cx({
        header: true,
        active: collapseState,
      })}
    >
      <div className="container">
        <div
          style={{
            boxShadow: '0.1875rem 0.125rem 0.3125rem rgba(122, 123, 231, 0.3)',
          }}
          className={cx({
            active: collapseState,
            header__container: true,
          })}
        >
          <Link className="menu__logo" to="/" aria-label="logo fundiin">
            <Icon.Logo />
          </Link>
          <div className="menu">
            <ul
              className="nav"
              style={{
                height: collapseState
                  ? 42 * (MENU.length - 1)
                  : isLargerTablet(width)
                    ? 'auto'
                    : 0,
              }}
            >
              {MENU.filter(({ isShow }) => isShow).map(m => (
                <li key={m.id}>
                  <NavLink
                    className="color-grey-1000"
                    to={m.path}
                    onClick={() => setCollapseState(false)}
                    aria-label={m.translateText}
                    target={
                      m.path === 'https://docs.fundiin.vn/v2/'
                        ? '_blank'
                        : '_self'
                    }
                  >
                    {t(m.translateText)}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <PrimaryButton
            className="register-btn"
            onClick={(event: SyntheticEvent) => {
              event.preventDefault();
              if (pathname === '/partners') {
                navigate('/partners#register');
              } else {
                navigate(`${pathname}#download-app`);
              }
            }}
          >
            {pathname === '/partners' ? 'Liên hệ hợp tác' : 'Đăng ký ngay'}
            <Icon.ArrowRight />
          </PrimaryButton>
          <button
            className={cx({
              active: collapseState,
              'collapse-menu-btn': true,
            })}
            onClick={handleCollapseMenu}
            aria-label="menu"
          >
            <Icon.CollapseMenu />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
