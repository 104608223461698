import brandQueries from '../brand-service/brand-service.query';
import { defineService } from '../service-creator';

const brandService = defineService({
  query: {
    getManyBrand: () => {
      const data = brandQueries.getMany();
      return data;
    },
  },
});

export default brandService;
