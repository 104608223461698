import { useTranslation } from 'react-i18next';

import { isSmallerTablet } from 'src/common/utils/helper';
import Carousel1 from 'src/components/Common/Carousel1';
import DownloadApp from 'src/components/DownloadApp';
import Hero from 'src/components/Layouts/Hero';
import { useDocumentTitle, useWindowSize } from 'src/hooks';

import Different from './components/Different';
import PaymentGuide from './components/PaymentGuide';
import PaymentGuideMobile from './components/PaymentGuideMobile';
import Solution from './components/Solution';

const Home = () => {
  const [width] = useWindowSize();
  useDocumentTitle('Fundiin - Home');
  const { t } = useTranslation();

  return (
    <div className="home">
      <Hero
        title={t('home.titles.heroText')}
        heroUrl="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/hero/_hero.jpeg"
        heroMobileUrl="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/hero/_hero-mobile.jpeg"
        heroTabletUrl="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/hero/_hero-tablet.jpeg"
      />
      <Carousel1 />
      <Different />
      <Solution />
      {isSmallerTablet(width) ? <PaymentGuideMobile /> : <PaymentGuide />}
      <DownloadApp />
    </div>
  );
};

export default Home;
