import { Modal } from 'antd';

import CarouselBase from 'src/components/Carousels/CarouselBase';
import useStepShopping from 'src/pages/home/hooks/useStepShopping';

import GuideSteps from './GuideSteps';

const ModalShopping = ({
  currentSlide = 0,
  open,
  onCancel,
  onChangeSlide,
}: any) => {
  const { data } = useStepShopping();

  return (
    <Modal
      title={data[currentSlide].title}
      footer={false}
      open={open}
      onCancel={onCancel}
      closable
      destroyOnClose
      className="modal-guide"
    >
      <CarouselBase
        arrows={true}
        autoplay={false}
        initialSlide={currentSlide}
        afterChange={onChangeSlide}
      >
        {data.map(({ id, steps }) => (
          <GuideSteps key={id} listSteps={steps} />
        ))}
      </CarouselBase>
    </Modal>
  );
};

export default ModalShopping;
