import cx from 'classnames';

const FundiinButton = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button className={cx(['fundiin-btn', className])} {...props}>
      {children}
    </button>
  );
};

export default FundiinButton;
