import DownloadApp from 'src/components/DownloadApp';
import { useDocumentTitle } from 'src/hooks';
import CarouselBrands from 'src/pages/brands/components/CarouselBrands';
import Category from 'src/pages/brands/components/Category';

const Brands = () => {
  useDocumentTitle('Fundiin - Brands');

  return (
    <div className="brands">
      <CarouselBrands />
      <Category />
      <DownloadApp />
    </div>
  );
};

export default Brands;
