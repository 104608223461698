import { useState } from 'react';

import { Icon } from 'src/assets/svg';
import TabsBase from 'src/components/Tabs/TabsBase';

import StepsPayInstallment from './StepsPayInstallment';
import StepsShopping from './StepsShopping/StepsShopping';

const PaymentGuideMobile = () => {
  const [activeKey, setActiveKey] = useState('0');

  return (
    <div className="container">
      <div className="payment-guide-mobile">
        <h3>Hướng dẫn thanh toán</h3>
        <TabsBase
          defaultActiveKey="0"
          onChange={setActiveKey}
          size="small"
          items={[
            {
              key: '0',
              label: `Hướng dẫn mua hàng`,
              children: <StepsShopping />,
              icon:
                activeKey === '0' ? (
                  <Icon.Card className="icon-shopping" />
                ) : (
                  <Icon.CardOutline className="icon-shopping parent" />
                ),
            },
            {
              key: '1',
              label: `Thanh toán các kỳ`,
              children: <StepsPayInstallment />,
              icon:
                activeKey === '1' ? (
                  <Icon.Pay className="icon-shopping" />
                ) : (
                  <Icon.PayOutline className="icon-shopping parent" />
                ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PaymentGuideMobile;
