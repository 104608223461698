import { SyntheticEvent } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from 'src/assets/svg';
import FourShellSection, {
  SubPropsFourShellSection,
} from 'src/components/Common/FourShellSection';

const Different = () => {
  const { t } = useTranslation();

  const Content: SubPropsFourShellSection[] = [
    {
      id: 1,
      icon: <Icon.Percent />,
      subTitle: t('home.labels.noInterestRates'),
      subContent: t('home.descriptions.noInterestRates'),
    },
    {
      id: 2,
      icon: <Icon.Check />,
      subTitle: t('home.labels.noAppRequired'),
      subContent: t('home.descriptions.noAppRequired'),
    },
    {
      id: 3,
      icon: <Icon.Eye />,
      subTitle: t('home.labels.transparentFees'),
      subContent: t('home.descriptions.transparentFees'),
    },
    {
      id: 4,
      icon: <Icon.DifferentSmile />,
      subTitle: t('home.labels.approvalIn5Seconds'),
      subContent: t('home.descriptions.approvalIn5Seconds'),
    },
  ];

  return (
    <FourShellSection
      shells={Content}
      className="different"
      textBtn={t('actions.registerNow')}
      title={t('home.titles.whatMakesFundiinDifferent')}
      onClickBtn={(event: SyntheticEvent) => {
        event.preventDefault();
        window.location.href =
          process.env.REACT_APP_DOWNLOAD_APP_URL ||
          'https://fundiin.page.link/8a9f';
      }}
    />
  );
};

export default Different;
