import { useTranslation } from 'react-i18next';

import { Icon } from 'src/assets/svg';

const DownloadApp = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div id="download-app" className="download-app__anchor-scroll" />
      <div className="download-app">
        <div className="container">
          <div className="download-app__content">
            <div className="download-app__item">
              <div className="key-visual">
                <img
                  src="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/download-app/_key-visual.webp"
                  alt="key visual bg"
                />
              </div>
              <div className="relative">
                <h3 className="download-app__title">
                  {t('downloadApp.titles.smootherAppExperience')}
                </h3>
                <div className="download-app__wrap">
                  <div className="download-app__qr">
                    <img
                      src="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/download-app/_qr-code.png"
                      alt="qr"
                    />
                  </div>
                  <div className="download-app__text">
                    <p className="download-app__subtitle">
                      {t('downloadApp.descriptions.smootherAppExperience')}
                    </p>
                    <p className="download-app__downloadtext">
                      {t('downloadApp.titles.scanCodeToDownloadApp')}
                    </p>
                  </div>
                </div>
                <div className="btn-site">
                  {[Icon.GooglePlay, Icon.AppStore].map(
                    (IconPlatform, index) => (
                      <a
                        key={index}
                        href={process.env.REACT_APP_DOWNLOAD_APP_URL}
                        aria-label="download app"
                      >
                        <IconPlatform />
                      </a>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="download-app__item">
              <div className="phone">
                <img
                  className="cover-img"
                  src="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/download-app/_download-app.webp"
                  alt="download bg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
