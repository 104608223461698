export const carouselsData = [
  {
    id: 1,
    alt: 'lotte',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/key-merchants/_lotte.png',
  },
  {
    id: 2,
    alt: 'pharmacity',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/key-merchants/_pharmacity.png',
  },
  {
    id: 3,
    alt: 'chotot',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/key-merchants/_chotot.png',
  },
  {
    id: 4,
    alt: 'ushop',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/key-merchants/_ushop.png',
  },
  {
    id: 5,
    alt: 'kidsplaza',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/key-merchants/_kidsplaza.png',
  },
  {
    id: 6,
    alt: 'cellphone',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/key-merchants/_cellphone.png',
  },
  {
    id: 7,
    alt: 'beautybox',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/key-merchants/_beautybox.png',
  },
  {
    id: 8,
    alt: 'coupletx',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/key-merchants/_coupletx.png',
  },
];
