export const carouselsData = [
  {
    id: 1,
    title:
      'Fundiin được vinh danh là giải pháp trả sau phổ biến nhất tại Việt Nam',
    stream: 'Tiền phong',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/newspaper/_img1.jpeg',
    source:
      'https://tienphong.vn/fundiin-duoc-vinh-danh-la-giai-phap-tra-sau-pho-bien-nhat-tai-viet-nam-post1566512.tpo',
  },
  {
    id: 2,

    title:
      'Giúp đối tác tăng AOV gấp 2 lần, Fundiin “độc chiếm" trên Shopify Việt Nam',
    stream: 'Báo Nhà Đầu Tư',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/newspaper/_img2.jpeg',
    source:
      'https://baodautu.vn/giup-doi-tac-tang-aov-gap-2-lan-fundiin-doc-chiem-tren-shopify-viet-nam-d196520.html',
  },
  {
    id: 3,

    title:
      'Fundiin bắt tay Pharmacity, mang giải pháp khoẻ trước trả sau trên thị trường bán lẻ dược phẩm',
    stream: 'Báo Nhà Đầu Tư',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/newspaper/_img3.jpeg',
    source:
      'https://baodautu.vn/fundiin-bat-tay-pharmacity-mang-giai-phap-khoe-truoc-tra-sau-den-thi-truong-ban-le-duoc-pham-d202958.html',
  },
  {
    id: 4,

    title: 'Fundiin nhận 5 triệu USD tại vòng gọi vốn Series A',
    stream: 'Báo Nhà Đầu Tư',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/newspaper/_img4.jpeg',
    source:
      'https://baodautu.vn/fundiin-nhan-5-trieu-usd-tai-vong-goi-von-series-a-d175668.html',
  },
  {
    id: 5,

    title: 'Chiến lược trụ vững trên thị trường mua trước, trả sau',
    stream: 'Báo Nhà Đầu Tư',
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/newspaper/_img5.jpeg',
    source:
      'https://baodautu.vn/chien-luoc-tru-vung-tren-thi-truong-mua-truoc-tra-sau-d196267.html',
  },
];
