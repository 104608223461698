import { useState } from 'react';

import { Col, Row } from 'antd';

import StepsBase from 'src/components/Steps/StepsBase';
import usePayInstallment from 'src/pages/home/hooks/usePayInstallment';

const StepsPayInstallment = () => {
  const { data } = usePayInstallment();
  const [currentStep, setCurrentStep] = useState<number>(0);

  return (
    <Row style={{ minHeight: '38.875rem' }} gutter={36}>
      <Col span={12}>
        <img
          className="cover-img"
          src={data[currentStep].img}
          alt="image for current step in pay installment"
        />
      </Col>
      <Col span={12} style={{ marginTop: 124 }}>
        <StepsBase
          direction="vertical"
          size="default"
          current={currentStep}
          onChange={setCurrentStep}
          items={data.map(({ title, description }) => {
            return {
              title,
              description,
            };
          })}
        />
      </Col>
    </Row>
  );
};

export default StepsPayInstallment;
