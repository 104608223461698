import { ReactComponent as RegisterSuccess } from './_register-success.svg';
import { ReactComponent as BabyOutline } from './category/_baby-outline.svg';
import { ReactComponent as Baby } from './category/_baby.svg';
import { ReactComponent as EducationOutline } from './category/_education-outline.svg';
import { ReactComponent as Education } from './category/_education.svg';
import { ReactComponent as EyesGlassOutline } from './category/_eyeglass-outline.svg';
import { ReactComponent as EyesGlass } from './category/_eyeglass.svg';
import { ReactComponent as FasionOutline } from './category/_fasion-outline.svg';
import { ReactComponent as Fasion } from './category/_fasion.svg';
import { ReactComponent as HeathOutline } from './category/_heath-outline.svg';
import { ReactComponent as Heath } from './category/_heath.svg';
import { ReactComponent as HomeOutline } from './category/_home-outline.svg';
import { ReactComponent as Home } from './category/_home.svg';
import { ReactComponent as HotBrandsOutline } from './category/_hotbrands-outline.svg';
import { ReactComponent as HotBrands } from './category/_hotbrands.svg';
import { ReactComponent as MenFasionOutline } from './category/_menfasion-outline.svg';
import { ReactComponent as MenFasion } from './category/_menfasion.svg';
import { ReactComponent as SportOutline } from './category/_sport-outline.svg';
import { ReactComponent as Sport } from './category/_sport.svg';
import { ReactComponent as Check } from './different/_check.svg';
import { ReactComponent as Eye } from './different/_eye.svg';
import { ReactComponent as Percent } from './different/_percent.svg';
import { ReactComponent as DifferentSmile } from './different/_smile.svg';
import { ReactComponent as CardOutline } from './direction/_card-outline.svg';
import { ReactComponent as Card } from './direction/_card.svg';
import { ReactComponent as LeftArrow } from './direction/_left-arrow.svg';
import { ReactComponent as PayOutline } from './direction/_pay-outline.svg';
import { ReactComponent as Pay } from './direction/_pay.svg';
import { ReactComponent as RightArrow } from './direction/_right-arrow.svg';
import { ReactComponent as Step1Outline } from './direction/_step1-outline.svg';
import { ReactComponent as Step1 } from './direction/_step1.svg';
import { ReactComponent as Step2Outline } from './direction/_step2-outline.svg';
import { ReactComponent as Step2 } from './direction/_step2.svg';
import { ReactComponent as Step3Outline } from './direction/_step3-outline.svg';
import { ReactComponent as Step3 } from './direction/_step3.svg';
import { ReactComponent as AppStore } from './download-app/_app-store.svg';
import { ReactComponent as GooglePlay } from './download-app/_google-play.svg';
import { ReactComponent as Clock } from './footer/_clock.svg';
import { ReactComponent as Facebook } from './footer/_fb.svg';
import { ReactComponent as Google } from './footer/_gg.svg';
import { ReactComponent as Headphone } from './footer/_headphone.svg';
import { ReactComponent as Location } from './footer/_location.svg';
import { ReactComponent as Mail } from './footer/_mail.svg';
import { ReactComponent as Zalo } from './footer/_zalo.svg';
import { ReactComponent as ArrowRight } from './header/_arrow-right.svg';
import { ReactComponent as CollapseMenu } from './header/_colapse-menu.svg';
import { ReactComponent as Logo } from './header/_logo.svg';
import { ReactComponent as Fire } from './solution/_fire.svg';
import { ReactComponent as SolutionSmile } from './solution/_smile.svg';
import { ReactComponent as Ads } from './togerther/_ads.svg';
import { ReactComponent as GrowthChart } from './togerther/_growth-chart.svg';
import { ReactComponent as Growth } from './togerther/_growth.svg';

export const Icon = {
  Ads,
  Pay,
  Eye,
  Card,
  Logo,
  Fire,
  Baby,
  Zalo,
  Mail,
  Home,
  Step1,
  Step2,
  Step3,
  Check,
  Clock,
  Heath,
  Sport,
  Google,
  Growth,
  Fasion,
  Percent,
  Facebook,
  Location,
  AppStore,
  Headphone,
  Education,
  EyesGlass,
  MenFasion,
  HotBrands,
  LeftArrow,
  PayOutline,
  ArrowRight,
  GooglePlay,
  RightArrow,
  GrowthChart,
  BabyOutline,
  CardOutline,
  HomeOutline,
  HeathOutline,
  SportOutline,
  CollapseMenu,
  Step1Outline,
  Step2Outline,
  Step3Outline,
  FasionOutline,
  SolutionSmile,
  DifferentSmile,
  RegisterSuccess,
  EducationOutline,
  EyesGlassOutline,
  HotBrandsOutline,
  MenFasionOutline,
};
