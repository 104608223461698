import { inferQuery } from 'src/services/infer-service';

const useBrands = () => {
  const { data, isLoading } = inferQuery({
    entity: 'brand',
    action: 'getManyBrand',
  })();

  return {
    isLoading,
    data: data || [],
  };
};

export default useBrands;
