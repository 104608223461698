import {
  mediaScreenMobile,
  mediaScreenTablet,
} from 'src/common/constants/size';

type PropsHero = {
  title: string;
  heroUrl: string;
  heroTabletUrl: string;
  heroMobileUrl: string;
};

const Hero = ({ title, heroUrl, heroMobileUrl, heroTabletUrl }: PropsHero) => {
  return (
    <div className="hero">
      <picture>
        <source
          media={`(min-width: ${mediaScreenTablet}px)`}
          srcSet={heroUrl}
        />
        <source
          media={`(min-width: ${mediaScreenMobile}px)`}
          srcSet={heroTabletUrl}
        />
        <img className="cover-img" src={heroMobileUrl} alt="hero" />
      </picture>
      <div className="hero__filter1"></div>
      <div className="hero__filter2"></div>
      <div className="hero__filter3"></div>
      <div className="hero__content container">
        <h1>{title}</h1>
      </div>
    </div>
  );
};

export default Hero;
