import { Tabs, TabsProps } from 'antd';
import classNames from 'classnames';

export interface TabsBaseProps extends TabsProps {}

const TabsBase = ({ className, ...rest }: TabsBaseProps) => {
  return <Tabs className={classNames('tabs-base', className)} {...rest} />;
};

export default TabsBase;
