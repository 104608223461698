import {
  QueryKey,
  UseMutationOptions as rqUseMutationOptions,
  useQuery as rqUseQuery,
  UseQueryOptions as rqUseQueryOptions,
  UseQueryResult as rqUseQueryResult,
} from '@tanstack/react-query';

type QueryDefinition<T = any> = {
  [X in keyof T]: (...args: any[]) => Promise<any>;
};

type MutationDefinition<T = any> = {
  [X in keyof T]: (...args: any[]) => Promise<any>;
};

type ServiceOptionalDefinition = {
  query?: QueryDefinition;
  mutation?: MutationDefinition;
};

type ServiceRequiredDefinition<T = any> = Required<T>;

export type ServiceMap<T = any> = {
  [X in keyof T]: ServiceRequiredDefinition<T>;
};

export interface UseQueryBaseOptions<T = any, MappedType = T, IError = Error> {
  retry?: number;
  enabled?: boolean;
  cacheFor?: number; // ms to cache the result
  markDataAsStaleAfter?: number;

  selector?: rqUseQueryOptions<T, IError, MappedType>['select']; // select/filter/transform to get data you want based on returned result
  onError?: rqUseMutationOptions<T, IError, MappedType>['onError'];
  onSuccess?: rqUseMutationOptions<T, IError, MappedType>['onSuccess'];
}

export type UseQueryBaseReturn<T> = rqUseQueryResult<T, Error> & {
  queryKey: QueryKey | undefined;
};

export function defineService<T extends ServiceOptionalDefinition>(service: T) {
  return {
    query: service.query ?? {},
    mutation: service.mutation ?? {},
  } as ServiceRequiredDefinition<T>;
}

const memorize: Record<string, any> = {};

export function createUseQuery<S extends ServiceMap>(service: S) {
  return function inferQuery<
    Entity extends keyof S,
    Action extends keyof S[Entity]['query'],
    Handler extends S[Entity]['query'][Action],
  >(inferredBase: {
    entity: Entity;
    action: Action;
  }): <T = Awaited<ReturnType<Handler>>, MappedType = T>(
    request?: Parameters<Handler>[0]
    // options: UseQueryBaseOptions<T, MappedType>
  ) => UseQueryBaseReturn<MappedType> {
    const queryKeyString = `${String(inferredBase.entity)}_${String(inferredBase.action)}`;

    if (memorize[queryKeyString]) {
      return memorize[queryKeyString];
    }

    function useQueryBase<T = Awaited<ReturnType<Handler>>, MappedType = T>(
      request?: Parameters<Handler>[0]
      // options: UseQueryBaseOptions<T, MappedType>
    ): UseQueryBaseReturn<MappedType> {
      const queryKey = queryKeyString;
      const querySource = {
        request,
      };

      return {
        queryKey: [queryKey, querySource],
        ...rqUseQuery({
          enabled: true, // if enabled is false, we need to call refetch manually
          queryKey: [queryKey, querySource],
          queryFn: () =>
            service[inferredBase.entity].query[inferredBase.action](request),
        }),
      };
    }
    memorize[queryKeyString] = useQueryBase;

    return memorize[queryKeyString];
  };
}
