import type { ThemeConfig } from 'antd';

export const themeConfig: ThemeConfig = {
  token: {
    fontFamily: 'Manrope',
    colorPrimary: '#6d5ff0',
    borderRadius: 6,
  },
  components: {
    Steps: {
      iconSizeSM: 16,
      titleLineHeight: 32,
    },
    Tabs: {
      horizontalItemGutter: 20,
    },
  },
};
