import cx from 'classnames';

export type SubPropsEightShellSection = {
  id: number;
  imgUrl: string;
};

type PropsEightShellSection = {
  title: string;
  subtitle: string;
  shells: SubPropsEightShellSection[];
} & React.HTMLAttributes<HTMLDivElement>;

const EightShellSection = ({
  title,
  subtitle,
  className,
  shells = [],
}: PropsEightShellSection) => {
  return (
    <div className={cx(['eight-shell', className])}>
      <div className="container">
        <h3 className="eight-shell__title text-center color-grey-1000">
          {title}
        </h3>
        <p className="eight-shell__subtitle">{subtitle}</p>
        <div className="eight-shell__content">
          {shells.map(s => (
            <div
              key={s.id}
              className={cx({
                empty: !s.imgUrl,
                'eight-shell__item': true,
              })}
            >
              {s.imgUrl ? (
                <div className="eight-shell__img">
                  <img src={s.imgUrl} alt="brands" />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EightShellSection;
