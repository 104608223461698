export const carouselsData: { id: number; url: string; link: string }[] = [
  {
    id: 1,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/ROUTINE_WEB.jpg',
    link: '',
  },
  {
    id: 2,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/CURVES_WEB.jpg',
    link: '',
  },
  {
    id: 3,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/REVISED_WEB.jpg',
    link: '',
  },
  {
    id: 4,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/REVISED_WEB2.jpg',
    link: '',
  },
  {
    id: 5,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/REVISED_WEB3.jpg',
    link: '',
  },
];
