import { useCallback, useState } from 'react';

import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { Document, Page, pdfjs } from 'react-pdf';

import { Footer, Header } from 'src/components/Layouts';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { Layout } from 'antd';

interface PdfViewProps {
  fileUrl: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const resizeObserverOptions = {};

const maxWidth = 2000;

const PdfView = ({ fileUrl }: PdfViewProps) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();

  const onResize = useCallback<ResizeObserverCallback>(entries => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onDocumentLoadSuccess({ numPages }: any) {
    setPageNumber(numPages);
  }

  return (
    <Layout.Content style={{ overflow: 'hidden' }}>
      <Header />

      <div className="container">
        <div className="layout-pdf-content" ref={setContainerRef}>
          <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(pageNumber), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={
                  containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
                }
              />
            ))}
          </Document>
        </div>
      </div>

      <Footer />
    </Layout.Content>
  );
};

export default PdfView;
