import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { isSmallerTablet } from 'src/common/utils/helper';
import { useWindowSize } from 'src/hooks';

const ScrollToAnchor = () => {
  const [width] = useWindowSize();
  const location = useLocation();

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (!location.hash) return;

    setTimeout(() => {
      if (location.hash && document.getElementById(location.hash.slice(1))) {
        document
          .getElementById(location.hash.slice(1))
          ?.scrollIntoView(
            isSmallerTablet(width)
              ? true
              : { behavior: 'smooth', block: 'start' }
          );
      }
    }, 100);
  }, [location, width]);

  return <></>;
};

export default ScrollToAnchor;
