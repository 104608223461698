import { useTranslation } from 'react-i18next';

import { Icon } from 'src/assets/svg';

import { EStatue } from '../../components/Category/const';

const useCategory = () => {
  const { t } = useTranslation();

  const brands = [
    {
      id: 0,
      altName: 'BRAND_HOT',
      icon: <Icon.HotBrands />,
      aciveIcon: <Icon.HotBrands />,
      text: t('brands.choices.hotBrands'),
    },
    {
      id: 1,
      altName: 'SUCKHOEVALAMDEP',
      icon: <Icon.HeathOutline />,
      aciveIcon: <Icon.Heath />,
      text: t('brands.choices.healthAndBeauty'),
    },
    {
      id: 2,
      altName: 'THOITRANGNU',
      icon: <Icon.FasionOutline />,
      aciveIcon: <Icon.Fasion />,
      text: t('brands.choices.womenFashion'),
    },
    {
      id: 3,
      altName: 'THOITRANGNAM',
      icon: <Icon.MenFasionOutline />,
      aciveIcon: <Icon.MenFasion />,
      text: t('brands.choices.menFashion'),
    },
    {
      id: 4,
      altName: 'PHUKIENTHOITRANG',
      icon: <Icon.EyesGlassOutline />,
      aciveIcon: <Icon.EyesGlass />,
      text: t('brands.choices.fashionAccessories'),
    },
    {
      id: 5,
      altName: 'NHACUADOISONG',
      icon: <Icon.HomeOutline />,
      aciveIcon: <Icon.Home />,
      text: t('brands.choices.homeAndLiving'),
    },
    {
      id: 6,
      altName: 'GIAODUC',
      icon: <Icon.EducationOutline />,
      aciveIcon: <Icon.Education />,
      text: t('brands.choices.education'),
    },
    {
      id: 7,
      altName: 'THETHAOVADULICH',
      icon: <Icon.SportOutline />,
      aciveIcon: <Icon.Sport />,
      text: t('brands.choices.sportsAndTravel'),
    },
    {
      id: 8,
      altName: 'MEVABE',
      icon: <Icon.BabyOutline />,
      aciveIcon: <Icon.Baby />,
      text: t('brands.choices.momsAndBabies'),
    },
    {
      id: 9,
      altName: 'KHAC',
      icon: <></>,
      aciveIcon: <></>,
      text: t('brands.choices.other'),
    },
  ];

  const buys = [
    {
      id: 1,
      value: EStatue.ONLINE,
      text: t('brands.choices.online'),
    },
    {
      id: 2,
      value: EStatue.OFFLINE,
      text: t('brands.choices.offline'),
    },
  ];

  return {
    brands,
    buys,
  };
};

export default useCategory;
