import { ButtonHTMLAttributes } from 'react';

import cx from 'classnames';

const PrimaryButton = ({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLButtonElement> &
  ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button className={cx(['gradian-btn', className])} {...props}>
      {children}
    </button>
  );
};

export default PrimaryButton;
