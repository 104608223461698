import { PropsWithChildren } from 'react';

import { px2remTransformer, StyleProvider } from '@ant-design/cssinjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';

import { themeConfig } from 'src/styles/themes';

const queryClient = new QueryClient();

const AppProvider = ({ children }: PropsWithChildren) => {
  return (
    <BrowserRouter>
      <ConfigProvider theme={themeConfig}>
        <StyleProvider
          layer //  the style of antd will always be lower than the default CSS selector priority
          transformers={[
            px2remTransformer({
              mediaQuery: true,
            }),
          ]}
        >
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </StyleProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default AppProvider;
