export enum EStatue {
  'UNCHECK' = '',
  'ONLINE' = 'ONLINE',
  'OFFLINE' = 'OFFLINE',
}

export type MerchantType = {
  image: string;
  website: string;
  keyTracking: number;
  runningForm: EStatue;
};

export type CategoryType = {
  id: number;
  altName: string;
  merchants: MerchantType[];
};
