import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icon } from 'src/assets/svg';
import FourShellSection, {
  SubPropsFourShellSection,
} from 'src/components/Common/FourShellSection';

const Together = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const Content: SubPropsFourShellSection[] = [
    {
      id: 1,
      icon: <Icon.GrowthChart />,
      subTitle: t('partners.titles.increaseRevenueBy30Percent'),
      subContent: t('partners.descriptions.increaseRevenueBy30Percent'),
    },
    {
      id: 2,
      icon: <Icon.Check />,
      subTitle: t('partners.titles.increaseAverageOrderValueBy1Point8Times'),
      subContent: t(
        'partners.descriptions.increaseAverageOrderValueBy1Point8Times'
      ),
    },
    {
      id: 3,
      icon: <Icon.Growth />,
      subTitle: t('partners.titles.optimizeBusinessCashFlow'),
      subContent: t('partners.descriptions.optimizeBusinessCashFlow'),
    },
    {
      id: 4,
      icon: <Icon.Ads />,
      subTitle: t('partners.titles.supportBrandPromotion'),
      subContent: t('partners.descriptions.supportBrandPromotion'),
    },
  ];

  return (
    <FourShellSection
      shells={Content}
      className="together"
      textBtn={t('actions.partnershipContact')}
      title={t('partners.titles.growWithFundiin')}
      onClickBtn={() => navigate('/partners#register')}
    />
  );
};

export default Together;
