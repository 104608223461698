import { useTranslation } from 'react-i18next';

import Carousel1 from 'src/components/Common/Carousel1';
import { Hero } from 'src/components/Layouts';
import { useDocumentTitle } from 'src/hooks';
import CaseStudy from 'src/pages/partners/components/CaseStudy';
import Integration from 'src/pages/partners/components/Integration';
import RegisterForm from 'src/pages/partners/components/RegisterForm';
import Together from 'src/pages/partners/components/Together';

const Partners = () => {
  useDocumentTitle('Fundiin - Partners');

  const { t } = useTranslation();

  return (
    <div className="partners">
      <Hero
        title={t('partners.titles.heroText')}
        heroUrl="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/hero/_hero-partner.jpeg"
        heroMobileUrl="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/hero/_hero-partner-mobile.jpeg"
        heroTabletUrl="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/hero/_hero-partner-tablet.jpeg"
      />
      <Carousel1 />
      <Together />
      <Integration />
      <CaseStudy />
      <RegisterForm />
    </div>
  );
};

export default Partners;
