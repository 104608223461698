import { useTranslation } from 'react-i18next';

const CoreValue = () => {
  const { t } = useTranslation();

  return (
    <div className="corevalue">
      <div className="corevalue__content">
        <div className="corevalue__text">
          <div>
            <h3 className="corevalue__title">{t('about.titles.coreValues')}</h3>
            <p>{t('about.descriptions.coreValues')}</p>
          </div>
        </div>
        <div className="corevalue__img">
          <img
            className="cover-img"
            src="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/_corevalue.png"
            alt="corevalue"
          />
        </div>
      </div>
    </div>
  );
};

export default CoreValue;
