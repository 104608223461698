import {
  mediaScreenMobile,
  mediaScreenTablet,
} from 'src/common/constants/size';

export const isLargerTablet = (px: number) => px > mediaScreenTablet;

export const isLargerMobile = (px: number) => px > mediaScreenMobile;

export const isSmallerPc = (px: number) => px <= mediaScreenTablet;

export const isSmallerTablet = (px: number) => px <= mediaScreenMobile;

export const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '6.25rem';
  (outer.style as any).msOverflowStyle = 'scrollbar'; // needed for WinJS apps

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer?.parentNode?.removeChild(outer);

  return widthNoScroll - widthWithScroll;
};

export function cloneDeep(obj: object) {
  return JSON.parse(JSON.stringify(obj));
}
