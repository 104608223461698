import { useState } from 'react';

import StepsBase from 'src/components/Steps/StepsBase';
import { GuideStepsData } from 'src/types';

export interface GuideStepsProps {
  listSteps: GuideStepsData[];
}

const GuideSteps = ({ listSteps }: GuideStepsProps) => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  return (
    <>
      <div className="guide-steps__img">
        <img
          className="cover-img"
          src={listSteps[currentStep]?.img}
          alt="image"
        />
      </div>

      <div className="guide-steps__wrap">
        <StepsBase
          direction="vertical"
          size="small"
          current={currentStep}
          onChange={setCurrentStep}
          items={listSteps.map(({ title, description }) => {
            return {
              title,
              description,
            };
          })}
        />
      </div>
    </>
  );
};

export default GuideSteps;
