import { ReactElement, SyntheticEvent } from 'react';

import cx from 'classnames';

import { Icon } from 'src/assets/svg';
import PrimaryButton from 'src/components/Common/PrimaryButton';

export type SubPropsFourShellSection = {
  id: number;
  subTitle: string;
  subContent: string;
  icon: ReactElement;
};

type PropsFourShellSection = {
  title: string;
  textBtn: string;
  shells: SubPropsFourShellSection[];
  onClickBtn: (event: SyntheticEvent) => void;
} & React.HTMLAttributes<HTMLDivElement>;

const FourShellSection = ({
  title,
  textBtn,
  className,
  onClickBtn,
  shells = [],
}: PropsFourShellSection) => {
  return (
    <div className={cx(['four-shell', className])}>
      <div className="container">
        <h2 className="four-shell__title text-center color-grey-1000">
          {title}
        </h2>
        <div className="four-shell__content">
          {shells.map(s => (
            <div key={s.id} className="four-shell__item">
              <div className="four-shell__icon">{s.icon}</div>
              <p className="four-shell__subtitle color-primary-text text-center">
                {s.subTitle}
              </p>
              <p className="four-shell__subcontent color-secondary-text text-center">
                {s.subContent}
              </p>
            </div>
          ))}
        </div>
        <div className="site-btn">
          <PrimaryButton className="register-btn" onClick={onClickBtn}>
            {textBtn} <Icon.ArrowRight />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default FourShellSection;
