import { GuideSlidesData } from 'src/types';

export const SHOPPING_GUIDE: GuideSlidesData[] = [
  {
    id: 'shopping',
    title: 'Mua hàng',
    steps: [
      {
        id: '1',
        img: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/direction/_buy1.webp',
        title: 'Mua hàng',
        description: (
          <p>
            Mua sắm tại các thương hiệu thuộc danh mục mua sắm của Fundiin và
            chọn Fundiin làm phương thức thanh toán.
          </p>
        ),
      },
      {
        id: '2',
        img: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/direction/_buy2.webp',
        title: 'Chọn Fundiin để thanh toán',
        description: (
          <>
            <p>
              Tiến hành đăng ký tài khoản nếu là lần đầu thanh toán qua Fundiin.
              Ngoài ra, bạn có thể đăng ký tài khoản trước bằng cách tải ứng
              dụng App Store hoặc Google Play Store.
            </p>
            <p>Điều kiện thanh toán trả sau với Fundiin:</p>
            <ul>
              <li>Là công dân Việt Nam trong độ tuổi ở từ 18 tuổi trở lên.</li>
              <li>
                Sở hữu Chứng minh thư hoặc CCCD bản gốc và đang còn hiệu lực.
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    id: 'dinhdanhtaikhoan',
    title: 'Định danh tài khoản',
    steps: [
      {
        id: '1',
        img: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/direction/_verify1.webp',
        title: 'Đăng ký tài khoản',
        description: (
          <p>
            Nhập số điện thoại chính chủ từ các nhà mạng Viettel, Mobifone,
            Vinaphone, thiết lập mật khẩu gồm 6 chữ số. Sau đó bấm “Tiếp tục”.
          </p>
        ),
      },
      {
        id: '2',
        img: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/direction/_verify2.webp',
        title: 'Định danh tài khoản',
        description: (
          <p>
            Xác thực tài khoản với 2 mặt CMND hoặc CCCD, và một hình selfie
            gương mặt.
          </p>
        ),
      },
      {
        id: '3',
        img: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/direction/_verify3.webp',
        title: 'Kích hoạt tài khoản',
        description: <p>Nhận kết quả định danh tài khoản thành công.</p>,
      },
    ],
  },
  {
    id: 'payment',
    title: 'Thanh toán kỳ 1',
    steps: [
      {
        id: '1',
        img: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/direction/_pay1.webp',
        title: 'Chọn lịch thanh toán',
        description: (
          <p>
            Chọn lịch thanh toán phù hợp với nhu cầu, và thêm mã giảm giá (nếu
            có).
          </p>
        ),
      },
      {
        id: '2',
        img: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/direction/_pay2.webp',
        title: 'Kiểm tra thông tin',
        description: (
          <p>
            Sau khi kiểm tra số tiền thanh toán kỳ 1 và số tiền dự kiến trả mỗi
            kỳ, bấm “Xác nhận”.
          </p>
        ),
      },
      {
        id: '3',
        img: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/direction/_pay3.webp',
        title: 'Chọn phương thức thanh toán',
        description: (
          <p>
            Chọn phương thức thanh toán và kiểm tra số tiền thanh toán kỳ 1.
          </p>
        ),
      },
    ],
  },
];
