import EightShellSection, {
  SubPropsEightShellSection,
} from 'src/components/Common/EightShellSection';

const Content: SubPropsEightShellSection[] = [
  {
    id: 1,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/investors/_trihill.png',
  },
  {
    id: 2,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/investors/_thinkzone.png',
  },
  {
    id: 3,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/investors/_ventures.png',
  },
  {
    id: 4,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/investors/_jafco.png',
  },
  {
    id: 5,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/investors/_genesia.png',
  },
  {
    id: 6,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/investors/_zone.png',
  },
  {
    id: 7,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/investors/_x.png',
  },
  {
    id: 8,
    imgUrl:
      'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/investors/_angel.png',
  },
];

const Investor = () => {
  return (
    <EightShellSection
      subtitle=""
      shells={Content}
      className="integration"
      title="Nhà đầu tư của Fundiin"
    />
  );
};

export default Investor;
