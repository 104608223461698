import axiosClient from 'src/internals/axios-client/axios-client';

import { BrandQuery } from '../service-types';

class BrandQueries {
  async getMany() {
    const data = await axiosClient.get<BrandQuery[]>(
      '/content/all-merchant-by-category'
    );
    return data.data;
  }
}

const brandQueries = new BrandQueries();

export default brandQueries;
