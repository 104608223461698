import LoadingInfinitive from 'src/components/Icons/LoadingInfinitive';
import Fullscreen from 'src/components/Layouts/Fullscreen';

const LoadingFullscreen = () => {
  return (
    <Fullscreen center>
      <LoadingInfinitive />
    </Fullscreen>
  );
};

export default LoadingFullscreen;
