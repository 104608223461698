import { useState } from 'react';

import { Icon } from 'src/assets/svg';
import usePayInstallment from 'src/pages/home/hooks/usePayInstallment';

import ModalPayInstallment from './ModalPayInstallment';

const StepsPayInstallment = () => {
  const { data } = usePayInstallment();
  const [currentStep, setCurrentStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModel = (index: number) => {
    setCurrentStep(index);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ul className="directtion-mobile__step">
        {data.map((step, i) => (
          <li key={step.id} onClick={() => handleOpenModel(i)}>
            <div>
              <p>Bước {i + 1}</p>
              <p>{step.title}</p>
            </div>
            <Icon.RightArrow />
          </li>
        ))}
      </ul>

      <ModalPayInstallment
        currentStep={currentStep}
        onChangeCurrentStep={setCurrentStep}
        onCancel={handleCancel}
        open={isModalOpen}
      />
    </>
  );
};

export default StepsPayInstallment;
