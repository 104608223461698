import cx from 'classnames';

import { Icon } from 'src/assets/svg';

type PropsTwoShellSection1 = {
  title: string;
  imgUrl: string;
  list: {
    id: number;
    text: string;
  }[];
  subTitle: string;
  theme?: 'light' | 'dark';
} & React.HTMLAttributes<HTMLDivElement>;

const TwoShellSection1 = ({
  title,
  imgUrl,
  subTitle,
  className,
  list = [],
  theme = 'light',
}: PropsTwoShellSection1) => {
  return (
    <div className={cx(['two-shell', className])}>
      <div className="two-shell__item">
        <h3 className="two-shell__title">{title}</h3>
        <p className={cx(['two-shell__subtitle', theme])}>{subTitle}</p>
        <ul
          style={{
            listStyleImage: `url(${Icon.Check})`,
          }}
          className="two-shell__list"
        >
          {list.map(l => (
            <li className={cx([theme])} key={l.id}>
              <Icon.Check className="parent" />
              <span>{l.text}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="two-shell__item">
        <div className="two-shell__crop">
          <img className="cover-img" src={imgUrl} alt="imgage" />
        </div>
      </div>
    </div>
  );
};

export default TwoShellSection1;
