import { useState } from 'react';

import { Col, Flex, Row } from 'antd';

import StepsBase from 'src/components/Steps/StepsBase';
import TagCheckable from 'src/components/Tags/TagCheckable';
import useStepShopping from 'src/pages/home/hooks/useStepShopping';

const StepsShopping = () => {
  const { data } = useStepShopping();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const onChangeTab = (tab: number) => {
    setCurrentStep(0);
    setCurrentTab(tab);
  };

  return (
    <Row style={{ minHeight: '38.875rem' }} gutter={36}>
      <Col span={12}>
        <img
          className="cover-img"
          src={data[currentTab].steps[currentStep].img}
          alt="image for current step in pay installment"
        />
      </Col>
      <Col span={12} style={{ padding: '3.5rem 1.125rem' }}>
        <Flex gap={5} style={{ marginBottom: 36 }} wrap>
          <TagCheckable
            key={0}
            checked={currentTab === 0}
            onChange={() => onChangeTab(0)}
          >
            Mua hàng
          </TagCheckable>
          <TagCheckable
            key={1}
            checked={currentTab === 1}
            onChange={() => onChangeTab(1)}
          >
            Định danh tài khoản
          </TagCheckable>
          <TagCheckable
            key={3}
            checked={currentTab === 2}
            onChange={() => onChangeTab(2)}
          >
            Thanh toán kỳ 1
          </TagCheckable>
        </Flex>
        <StepsBase
          direction="vertical"
          size="default"
          current={currentStep}
          onChange={setCurrentStep}
          items={data[currentTab].steps.map(({ title, description }) => {
            return {
              title,
              description,
            };
          })}
        />
      </Col>
    </Row>
  );
};

export default StepsShopping;
