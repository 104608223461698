import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import { Footer, Header } from 'src/components/Layouts';
import ScrollToAnchor from 'src/pages/partners/components/ScrollToAnchor';

const LayoutDefault = () => {
  return (
    <Layout.Content style={{ overflow: 'hidden' }}>
      <Header />
      <ScrollToAnchor />
      <Outlet />
      <Footer />
    </Layout.Content>
  );
};

export default LayoutDefault;
