import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon } from 'src/assets/svg';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="container">
        <Flex vertical style={{ marginBottom: 20 }}>
          <div className="footer__logo">
            <Icon.Logo />
          </div>
          <div className="footer__social">
            <Link
              to="https://www.facebook.com/fundiin"
              target="_blank"
              aria-label="link facebook"
            >
              <Icon.Facebook />
            </Link>
            <Link
              to="https://zalo.me/4608879497973274078"
              target="_blank"
              aria-label="link zalo"
            >
              <Icon.Zalo />
            </Link>
          </div>
        </Flex>

        <div className="footer__content">
          <div className="footer__item">
            <h4 className="footer__title">
              {t('footer.descriptions.companyName')}
            </h4>
            <ul className="footer__info">
              <li>
                <Icon.Location />{' '}
                <span>{t('footer.descriptions.companyLocation')}</span>
              </li>
              <li>
                <Icon.Clock />{' '}
                <span>{t('footer.descriptions.companyOperatingHours')}</span>
              </li>
              <li>
                <Icon.Mail />
                <a href="mailto:lienhe@fundiin.vn">lienhe@fundiin.vn</a>
              </li>
              <li>
                <Icon.Headphone /> <a href="tel:02836221097">028 3622 1097</a>
              </li>
            </ul>
            <p className="footer__lisence">
              {t('footer.descriptions.companyLicense')}
            </p>
          </div>
          <div className="footer__item">
            <div className="footer__subcontent">
              <div className="footer__subitem">
                <ul>
                  <li>
                    <Link to="/">{t('footer.titles.introduction')}</Link>
                  </li>
                  <li>
                    <Link to="/about">{t('navigation.aboutFundiin')}</Link>
                  </li>
                  <li>
                    <Link to="/brands">
                      {t('navigation.shoppingCategories')}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer__subitem">
                <ul>
                  <li>
                    <Link to="/partners">{t('footer.titles.partner')}</Link>
                  </li>
                  <li>
                    <Link to="/partners">{t('navigation.forPartners')}</Link>
                  </li>
                </ul>
              </div>
              <div className="footer__subitem">
                <ul>
                  <li>
                    <a href="#">{t('footer.titles.policyAndTerms')}</a>
                  </li>
                  <li>
                    <Link target="_blank" to="/purchase-contract">
                      {t('navigation.termsOfPurchase')}
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to="/privacy">
                      {t('navigation.privacyPolicy')}
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to="/contract-principles">
                      {t('navigation.principleContract')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
