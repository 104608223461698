import { useState } from 'react';

import { Modal } from 'antd';

import StepsBase from 'src/components/Steps/StepsBase';
import usePayInstallment from 'src/pages/home/hooks/usePayInstallment';

export interface ModalPayInstallmentProps {
  currentStep: number;
  onChangeCurrentStep: (step: number) => void;
  open: boolean;
  onCancel: () => void;
}

const ModalPayInstallment = ({
  currentStep,
  onChangeCurrentStep,
  open,
  onCancel,
}: ModalPayInstallmentProps) => {
  const { data } = usePayInstallment();
  const [currentTitle, setCurrentTitle] = useState<string>(
    data[currentStep].title
  );

  return (
    <Modal
      title={currentTitle}
      footer={false}
      open={open}
      onCancel={onCancel}
      closable
      destroyOnClose
      className="modal-guide"
    >
      <>
        <div className="guide-steps__img">
          <img className="cover-img" src={data[currentStep].img} alt="image" />
        </div>
        <div className="guide-steps__wrap">
          <StepsBase
            direction="vertical"
            size="small"
            current={currentStep}
            onChange={step => {
              onChangeCurrentStep(step);
              setCurrentTitle(data[step].title);
            }}
            items={data.map(({ title, description }) => {
              return {
                title,
                description,
              };
            })}
          />
        </div>
      </>
    </Modal>
  );
};

export default ModalPayInstallment;
