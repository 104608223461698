import { useTranslation } from 'react-i18next';

import { Icon } from 'src/assets/svg';
import { PropsTab1ContentSection } from 'src/pages/home/components/Tab1';
import TwoShellSection1 from 'src/pages/home/components/TwoShellSection1';

const useSolution = () => {
  const { t } = useTranslation();

  const content: PropsTab1ContentSection[] = [
    {
      id: 1,
      title: (
        <>
          <Icon.Fire />
          <span>{t('home.actions.freePayLater')}</span>
        </>
      ),
      content: (
        <TwoShellSection1
          className="bg-primary color-primary-content"
          title={t('home.titles.freePayLater')}
          subTitle={t('home.descriptions.freePayLater')}
          imgUrl="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/solutions/_0.webp"
          list={[
            {
              id: 1,
              text: t('home.descriptions.freePayLater1st'),
            },
            {
              id: 2,
              text: t('home.descriptions.freePayLater2nd'),
            },
            {
              id: 3,
              text: t('home.descriptions.freePayLater3rd'),
            },
          ]}
        />
      ),
    },
    {
      id: 2,
      title: <span>{t('home.actions.flexiblePayLater')}</span>,
      content: (
        <TwoShellSection1
          theme="dark"
          className="bg-secondary color-grey-600"
          title={t('home.titles.flexiblePayLater')}
          subTitle={t('home.descriptions.flexiblePayLater')}
          imgUrl="https://storage.googleapis.com/fundiin-asset/homepage/assets/images/solutions/_12.webp"
          list={[
            {
              id: 1,
              text: t('home.descriptions.flexiblePayLater1st'),
            },
            {
              id: 2,
              text: t('home.descriptions.flexiblePayLater2nd'),
            },
            {
              id: 3,
              text: t('home.descriptions.flexiblePayLater3rd'),
            },
          ]}
        />
      ),
    },
  ];

  return {
    content,
  };
};

export default useSolution;
