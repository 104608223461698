import { useState } from 'react';

import { Icon } from 'src/assets/svg';
import useStepShopping from 'src/pages/home/hooks/useStepShopping';

import ModalShopping from './ModalShopping';

const StepsShopping = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const { data } = useStepShopping();

  const handleOpenModel = (index: number) => {
    setCurrentSlide(index);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ul className="directtion-mobile__step">
        {data.map((slide, i) => (
          <li key={slide.id} onClick={() => handleOpenModel(i)}>
            <div>
              <p>Bước {i + 1}</p>
              <p>{slide.title}</p>
            </div>
            <Icon.RightArrow />
          </li>
        ))}
      </ul>

      <ModalShopping
        currentSlide={currentSlide}
        open={isModalOpen}
        onCancel={handleCancel}
        onChangeSlide={(slide: number) => setCurrentSlide(slide)}
      />
    </>
  );
};

export default StepsShopping;
