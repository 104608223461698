import { useEffect, useState } from 'react';

import { Radio } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { cloneDeep } from 'src/common/utils/helper';
import PrimaryButton from 'src/components/Common/PrimaryButton';

import useCategory from '../../hooks/useCategory/useCategory';

type PropsMenuMobile = {
  activeTabs: boolean[];
  onChange: (activeTabs: boolean[]) => void;
};

const MenuMobile = ({ activeTabs, onChange = () => {} }: PropsMenuMobile) => {
  const { t } = useTranslation();
  const { brands } = useCategory();
  const [intenalActiveTabs, setActiveTabs] = useState<boolean[]>([]);

  const handleActiveTab = (id: number) => {
    let atTabs = cloneDeep(intenalActiveTabs);

    atTabs[id] = !atTabs[id];

    // this line for single choise
    atTabs = atTabs.map((val: boolean, i: number) => (id === i ? val : false));

    setActiveTabs(atTabs);
  };

  const handleChange = () => {
    onChange(intenalActiveTabs);
  };

  useEffect(() => {
    setActiveTabs(activeTabs);
  }, [activeTabs]);

  return (
    <div className="category__menu--mobile">
      <ul>
        {brands.map(b => (
          <li
            key={b.id}
            className={cx({
              active: intenalActiveTabs[b.id],
            })}
            onClick={() => handleActiveTab(b.id)}
          >
            <span>
              {intenalActiveTabs[b.id] ? b.aciveIcon : b.icon} {b.text}
            </span>
            <Radio checked={!!intenalActiveTabs[b.id]}></Radio>
          </li>
        ))}
      </ul>
      <PrimaryButton
        type="button"
        className="register-btn"
        onClick={handleChange}
      >
        {t('brands.actions.apply')}
      </PrimaryButton>
    </div>
  );
};

export default MenuMobile;
