import { useTranslation } from 'react-i18next';

const LifeBester = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="life-bester">
        <h3 className="life-bester__title">
          {t('about.titles.lessWorryLiveFully')}
        </h3>
        <p>{t('about.descriptions.lessWorryLiveFully1st')}</p>
        <p>{t('about.descriptions.lessWorryLiveFully2nd')}</p>
      </div>
    </div>
  );
};

export default LifeBester;
