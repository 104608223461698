import { useTranslation } from 'react-i18next';

import Tab1 from 'src/pages/home/components/Tab1';

import useSolution from '../../hooks/useSolution/useSolution';

const Solution = () => {
  const { t } = useTranslation();

  const { content } = useSolution();

  return (
    <div className="container">
      <Tab1
        tabs={content}
        className="solution"
        defaultActiveTab={content[0].id}
        title={t('home.titles.paymentSolution')}
      />
    </div>
  );
};

export default Solution;
