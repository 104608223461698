export const MENU: {
  id: number;
  path: string;
  notRender?: boolean;
  translateText: string;
  isShow: boolean;
}[] = [
  {
    id: 1,
    path: '/brands',
    translateText: 'navigation.shoppingCategories',
    isShow: true,
  },
  {
    id: 2,
    path: '/partners',
    translateText: 'navigation.forPartners',
    isShow: true,
  },
  {
    id: 3,
    notRender: true,
    path: 'https://docs.fundiin.vn/v2/',
    translateText: 'navigation.developers',
    isShow: true,
  },
  {
    id: 4,
    path: '/about',
    translateText: 'navigation.aboutFundiin',
    isShow: true,
  },
  {
    id: 5,
    path: '/privacy',
    translateText: 'navigation.privacyPolicy',
    isShow: false,
  },
  {
    id: 6,
    path: '/request-delete-account',
    translateText: 'navigation.deleteAccount',
    isShow: false,
  },
];
