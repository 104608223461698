import { Link } from 'react-router-dom';

import CarouselBase from 'src/components/Carousels/CarouselBase';

export type CarouselData2 = {
  id: number;
  url: string;
  title: string;
  stream: string;
  source: string;
};

export type PropsCarousel2 = {
  data: CarouselData2[];
};

const Carousel2 = ({ data }: PropsCarousel2) => {
  return (
    <div className="carousel2">
      <CarouselBase waitForAnimate={false}>
        {(data || []).map(c => (
          <div key={c.id} className="carousel2__wrap">
            <Link
              to={c.source}
              target="_blank"
              role="article"
              draggable={false}
            >
              <div className="carousel2__spacing">
                <div className="carousel2__img">
                  <img className="cover-img" src={c.url} alt="case study" />
                </div>
                <div className="carousel2__text">
                  <span className="carousel2__stream">{c.stream}</span>
                  <h5 className="carousel2__title line-clamp2">{c.title}</h5>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </CarouselBase>
    </div>
  );
};

export default Carousel2;
