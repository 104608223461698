import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';

export interface ButtonBaseProps extends ButtonProps {}

const ButtonBase = ({ className, ...rest }: ButtonBaseProps) => {
  return <Button className={classNames(className, 'button-base')} {...rest} />;
};

export default ButtonBase;
