import { useState } from 'react';

import { Flex } from 'antd';

import { Icon } from 'src/assets/svg';
import ButtonBase from 'src/components/Buttons/ButtonBase';

import StepsPayInstallment from './StepsPayInstallment';
import StepShopping from './StepsShopping/StepsShopping';

const PaymentGuide = () => {
  const [currentTab, setCurrenTab] = useState<number>(0);

  return (
    <div className="container" style={{ paddingBottom: 80 }}>
      <h2>Hướng dẫn thanh toán</h2>
      <Flex gap={20} style={{ marginBottom: 24 }}>
        <ButtonBase
          size="large"
          icon={<Icon.CardOutline className="parent" />}
          onClick={() => setCurrenTab(0)}
          type={currentTab === 0 ? 'primary' : 'default'}
        >
          Hướng dẫn mua hàng
        </ButtonBase>
        <ButtonBase
          size="large"
          icon={<Icon.PayOutline className="parent" />}
          onClick={() => setCurrenTab(1)}
          type={currentTab === 1 ? 'primary' : 'default'}
        >
          Thanh toán các kỳ
        </ButtonBase>
      </Flex>
      {currentTab === 0 ? <StepShopping /> : <StepsPayInstallment />}
    </div>
  );
};

export default PaymentGuide;
