type Choices = {
  label: string;
  value: string | number;
};

export const MERCHANT_TYPES: Choices[] = [
  {
    label: 'Ngành hàng không',
    value: 'AIRLINES',
  },
  {
    label: 'Ngành dịch vụ vui chơi giải trí',
    value: 'AMUSEMENT_AND_ENTERTAINMENT',
  },
  {
    label: 'Ngành dịch vụ cho thuê xe',
    value: 'AUTOMOBILE_VEHICLE_RENTALS',
  },
  {
    label: 'Ngành công nghiệp ô tô và xe máy',
    value: 'AUTOMOBILE_AND_VEHICLES',
  },
  {
    label: 'Ngành thương mại doanh nghiệp (B2B)',
    value: 'BUSINESS_SERVICES',
  },
  {
    label: 'Ngành kinh doanh khách sạn',
    value: 'HOTELS_AND_MOTELS',
  },
  {
    label: 'Ngành thời trang',
    value: 'CLOTHING_STORES',
  },
  {
    label: 'Ngành dịch vụ thi công',
    value: 'CONTRACTED_SERVICES',
  },
  {
    label: 'Ngành dịch vụ công',
    value: 'GOVERNMENT_SERVICES',
  },
  {
    label: 'Ngành thương mại điện tử',
    value: 'MAIL_ORDER_PROVIDERS',
  },
  {
    label: 'Ngành dịch vụ kinh doanh và nhượng quyền thương hiệu',
    value: 'MISCELLANEOUS_STORES',
  },
  {
    label: 'Ngành dịch vụ cá nhân',
    value: 'PERSONAL_SERVICE_PROVIDERS',
  },
  {
    label: 'Ngành dịch vụ giáo dục',
    value: 'PROFESSIONAL_SERVICES_AND_MEMBERSHIP_ORG',
  },
  {
    label: 'Ngành dịch vụ sửa chữa',
    value: 'REPAIR_SERVICES',
  },
  {
    label: 'Ngành cửa hàng bán lẻ (quy mô nhỏ)',
    value: 'RETAIL_STORES',
  },
  {
    label: 'Ngành cung cấp dịch vụ hàng ngày',
    value: 'SERVICE_PROVIDERS',
  },
  {
    label: 'Ngành giao thông vận tải - du lịch',
    value: 'TRANSPORTATION',
  },
  {
    label: 'Ngành tiện ích',
    value: 'UTILITIES',
  },
  {
    label: 'Ngành sản xuất và phân phối',
    value: 'DISTRIBUTORS_AND_MANUFACTURES',
  },
  {
    label: 'Các ngành khác',
    value: 'OTHERS',
  },
];
