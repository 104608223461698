import { useTranslation } from 'react-i18next';

import Carousel2 from 'src/components/Common/Carousel2';

import { carouselsData } from './const';

const Review = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="review">
        <h3 className="review__title">
          {t('about.titles.whatThePressSaysAboutFundiin')}
        </h3>
        <Carousel2 data={carouselsData}></Carousel2>
      </div>
    </div>
  );
};

export default Review;
