import { HtmlHTMLAttributes } from 'react';

import { Layout } from 'antd';

export interface FullscreenProps extends HtmlHTMLAttributes<HTMLDivElement> {
  center?: boolean;
}

const Fullscreen = (props: FullscreenProps) => {
  const { center, children, ...rest } = props;

  return (
    <Layout
      style={{
        height: '100vh',
        overflow: 'hidden',
        overflowY: 'scroll',
        backgroundColor: '#fff',
        ...(center && { alignItems: 'center', justifyContent: 'center' }),
      }}
      {...rest}
    >
      {children}
    </Layout>
  );
};

export default Fullscreen;
