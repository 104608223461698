import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DefaultLang } from 'src/common/constants/other';

import vi from './vi.json';

const i18next = i18n;

const resources = {
  en: {
    translation: vi,
  },
  vi: {
    translation: vi,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: DefaultLang,
  fallbackLng: DefaultLang,
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
