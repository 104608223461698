import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import AppRouter from 'src/app/AppRouter';

import 'src/styles/index.scss';

const App = () => {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <AppRouter />;
};

export default App;
